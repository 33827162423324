<script>
import ShowCase from './sections/Showcase.vue'
import WordOfTheDay from './sections/WordOfTheDay.vue'
import FeatureS from './sections/Features.vue'
import KarbiGrammar from './sections/Grammar.vue'
import WordIndices from './sections/WordIndices.vue'

export default {
	name: 'MainComponent',

	components: {
		ShowCase,
		WordOfTheDay,
		FeatureS,
		KarbiGrammar,
		WordIndices
	}
}
</script>

<template>
	<main role="main">
		<ShowCase />
		<WordOfTheDay />
		<FeatureS />
		<KarbiGrammar />
		<WordIndices />
	</main>
</template>
