<template>
  <main role="main">
    <section id="hero">
      <div class="container padding-y-xl9">
        <h1 class="size-xl2">Index of Words</h1>
      </div>
    </section>
    <router-view />
  </main>
</template>

<script>
export default {
  name: 'IndexOfWordView',
  mounted() {
    window.scrollTo(0, 0);
  },
}
</script>

<style></style>