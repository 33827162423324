<template>
  <div id="a" class="container">
    <h3>Words starting with A</h3>
    <div class="row">
      <div class="col-12 tablet:col-6">
        <a href="#" class="Aa">a</a>
        <a href="#" class="Aa">aba</a>
        <a href="#" class="Aa">abachu-bachu</a>
        <a href="#" class="Aa">abahak</a>
        <a href="#" class="Aa">abajar</a>
        <a href="#" class="Aa">aban</a>
        <a href="#" class="Aa">aban damkri</a>
        <a href="#" class="Aa">aban damthor</a>
        <a href="#" class="Aa">abang</a>
        <a href="#" class="Aa">abat</a>
        <a href="#" class="Aa">abek</a>
        <a href="#" class="Aa">aber</a>
      </div>
      <div class="col-12 tablet:col-6">
        <a href="#" class="Aa">abet</a>
        <a href="#" class="Aa">abik</a>
        <a href="#" class="Aa">abipo</a>
        <a href="#" class="Aa">abo-abat</a>
        <a href="#" class="Aa">aboi</a>
        <a href="#" class="Aa">aboi aboi</a>
        <a href="#" class="Aa">abok</a>
        <a href="#" class="Aa">abong athe</a>
        <a href="#" class="Aa">abung</a>
        <a href="#" class="Aa">abung-aram</a>
        <a href="#" class="Aa">achatchat</a>
        <a href="#" class="Aa">achar</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AIndex'
}
</script> href="#"