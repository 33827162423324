<template>
	<footer class="padding-top-xl7">
		<div class="container">
			<div class="row">
				<div class="col-12 tablet:col-12 laptop:col-6 margin-bottom-xl">
					<div class="home padding-bottom-xl display-flex">
						<a href="/" class="opacity-90" title="Home">
							<img src="/images/site_logo/kl_logo1.svg" alt="Home">
						</a>
					</div>
					<p>Karbi Lamtasam is an online dictionary aimed at preserving and promoting the Karbi language and culture.</p>
					<p>Made with <span aria-label="Love"><span class="fa-solid fa-heart"></span></span> in Hanjanglangso, Karbi Anglong.</p>
				</div>
				<div class="col-12 tablet:col-12 laptop:col-6 order-first table:order-last margin-bottom-xl2">
					<div class="row">
						<div class="col-6 tablet:col-4 display-flex flex-column float-left">
							<h4 style="color: grey; ">Quick Links</h4>
							<div class="margin-bottom-xs2">
								<a href="#" class="no-underline footer-link">Karbi Grammar</a>
							</div>
							<div class="margin-bottom-xs2">
								<a href="#" class="no-underline footer-link">Word of the Day</a>
							</div>
						</div>
						<div class="col-6 tablet:col-4 display-flex flex-column float-left">
							<h4 style="color: grey; ">Site Info</h4>
							<div class="margin-bottom-xs2">
								<a href="#" class="no-underline footer-link">About Karbi Lamtasam</a>
							</div>
							<div class="margin-bottom-xs2">
								<a href="/privacy" @click.prevent="goToPrivacyPolicy" class="no-underline footer-link">
									Privacy Policy
								</a>
							</div>
							<div class="margin-bottom-xs2">
								<a href="/terms_of_service" @click.prevent="goToTermsOfService" class="no-underline footer-link">
									Terms of Service
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
				<div class="margin-bottom-xl2">
						<h4 style="color: grey; ">Social Networks</h4>
						<a href="#" class="no-underline margin-right-xs">
								<span class="fa-brands fa-facebook"></span>
								<span class="margin-left-xs3">Facebook</span>
						</a>
						<a href="#" class="no-underline margin-right-xs">
								<span class="fa-brands fa-telegram"></span>
								<span class="margin-left-xs3">Telegram</span>
						</a>
						<a href="#" class="no-underline margin-right-xs">
								<span class="fa-brands fa-instagram"></span>
								<span class="margin-left-xs3">Instagram</span>
						</a>
				</div>
		</div>
	</footer>
	<div class="copy padding-x-md padding-y-lg2 text-center">
		<p class="size-sm tablet:size-sm2">Karbi Lamtasam, Copyright &copy; {{ yyyy }}</p>
	</div>
</template>

<script>
export default {
  name: 'FooterComponent',

	methods: {
		goToPrivacyPolicy() {
			this.$router.push('/privacy');
		},
		goToTermsOfService() {
			this.$router.push('/terms_of_service');
		}
	},
	data() {
		let d = new Date();
		return {
			yyyy: d.getFullYear()
		};
	}
};
</script>
