<template>
	<section id="word-indices" class="padding-y-xl7">
		<div class="container">
			<div>
				<h1>Browse Karbi Lamtasam</h1>
				<div class="word-index-link display-flex flex-wrap text-UPPERCASE padding-top-lg">
					<a href="/index_of_words/a" @click.prevent="goToAIndex" class="no-underline">a</a>
					<a href="/index_of_words/b" @click.prevent="goToBIndex" class="no-underline">b</a>
					<a href="#" class="no-underline">c</a>
					<a href="#" class="no-underline">d</a>
					<a href="#" class="no-underline">e</a>
					<a href="#" class="no-underline">f</a>
					<a href="#" class="no-underline">g</a>
					<a href="#" class="no-underline">h</a>
					<a href="#" class="no-underline">i</a>
					<a href="#" class="no-underline">j</a>
					<a href="#" class="no-underline">k</a>
					<a href="#" class="no-underline">l</a>
					<a href="#" class="no-underline">m</a>
					<a href="#" class="no-underline">n</a>
					<a href="#" class="no-underline">o</a>
					<a href="#" class="no-underline">p</a>
					<a href="#" class="no-underline">q</a>
					<a href="#" class="no-underline">r</a>
					<a href="#" class="no-underline">s</a>
					<a href="#" class="no-underline">t</a>
					<a href="#" class="no-underline">u</a>
					<a href="#" class="no-underline">v</a>
					<a href="#" class="no-underline">w</a>
					<a href="#" class="no-underline">x</a>
					<a href="#" class="no-underline">y</a>
					<a href="#" class="no-underline">z</a>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
  name: 'WordIndices',
	methods: {
		goToAIndex() {
			this.$router.push('/index_of_words/a');
		},
		goToBIndex() {
			this.$router.push('/index_of_words/b');
		},
	}
}
</script>