<template>
	<section id="grammar" class="padding-y-xl7 text-left">
		<div class="container">
			<div class="tablet:text-center line-length-xl margin-auto padding-bottom-xl5 position-relative">
				<h1>Introducing the Karbi Grammar!</h1>
				<p>With the introduction to Karbi grammar, Karbi Lamtasam brings you a comprehensive guide to the Karbi language. It includes an overview of the language structure and detailed grammar explanations. Karbi Lamtasam provides an in-depth look at Karbi grammar, covering topics such as nouns, verbs, adjectives, adverbs, conjunctions and more. It also includes an introduction to syntax and an overview of the language's history and cultural context.</p>
			</div>
			<div class="border-radius-xl padding-lg" style="background-color: black;">
				<div class="row align-stretch">
					<div class="col-12 laptop:col-7">
						<div>
							<h2>Grammar</h2>
							<div class="grammar-search display-flex flex-items-center margin-y-xl padding-y-xs padding-x-md">
								<span class="margin-right-xs3" style="cursor: default !important;">
								<span class="fa-solid fa-magnifying-glass"></span>
								</span>
								<input type="search" placeholder="Search" id="search">
							</div>
							<p>Grammar is what makes a language unique and gives it structure. It helps you understand the language system more easily. Without grammar, a language would be a jumble of words, making it difficult to comprehend.</p>
							<div class="display-flex flex-content-center padding-y-xl">
								<a href="#" class="btn padding-x-xl8 tablet:padding-x-xl12 no-underline" style="background-color: var(--blue-9);">Learn Karbi Grammar</a>
							</div>
						</div>
					</div>
					<div class="col-12 laptop:col-5">
						<h4>Topics</h4>
						<p>Topic 1</p>
						<p>Topic 2</p>
						<p>Topic 3</p>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'KarbiGrammar'
}
</script>

<style></style>