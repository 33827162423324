<template>
	<section id="WOTD" class="container padding-y-xl7" style="--grid-spacing-x-width: 0.5rem;">
		<div class="row spacious">
			<div class="col-12 laptop:col-7">
				<div class="margin-y-sm padding-lg text-center" style="border-radius: var(--border-radius-lg); background-color: rgb(42, 42, 42);">
					<h2 class="text-UPPERCASE">Word of the Day</h2>
					<p style="color: var(--text-lt-gray2);">
						<span class="margin-right-xs3">
							<span class="fa-solid fa-calendar-days"></span>
						</span>
						<span>{{ dd }} {{ mmm }}, {{ yyyy }}</span>
					</p>
					<div class="margin-y-xl text-left">
						<h3>kaprek</h3>
						<p><strong>Pronunciation</strong> IPA: /kaprek/</p>
					</div>
					<a href="#" class="btn padding-x-xl10 tablet:padding-x-xl12 no-underline" style="background-color: #7676e6;">See Definition</a>
				</div>
			</div>
			<div class="col-12 laptop:col-5">
				<div class="margin-y-sm padding-lg" style="border-radius: var(--border-radius-lg); background-color: rgb(42, 42, 42);">
					<h4>Lorem ipsum..</h4>
					<p>Some texts</p>
					<p>Another text</p>
					<p>Another line</p>
					<p>More lines</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'WordOfTheDay',
	
	data() {
		let d = new Date();
		var m = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		return {
			dd: d.getUTCDate(), mmm: m[d.getUTCMonth()], yyyy: d.getUTCFullYear()
		};
	}
}
</script>

<style></style>