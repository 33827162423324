<template>
  <main role="main">
    <section id="hero" class="padding-y-xl9">
      <div class="container">
        <h1 class="size-xl2 margin-bottom-xs">Terms of Service</h1>
        <p style="color: grey;">Updated: 25 Aug, 2024</p>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="tos-contents">
          <div class="padding-y-xl9">
            <p>Welcome to Karbi Lamtasam, a free online dictionary dedicated to the Karbi language. By accessing and using this website, you agree to comply with and be bound by the following Terms of Service. Please read them carefully.</p>
          </div>
          <div class="padding-y-xl9">
            <h3>Acceptance of Terms</h3>
            <p>By using Karbi Lamtasam, you acknowledge that you have read, understood, and agree to these Terms of Service. If you do not agree with these terms, please refrain from using my website.</p>
          </div>
          <div class="padding-y-xl9">
            <h3>Use of the Website</h3>
            <ol>
              <li class="margin-bottom-md"><strong>Personal Use:</strong> Karbi Lamtasam is provided for your personal, non-commercial use. You may not use the website for any illegal or unauthorized purposes.</li>
              <li class="margin-bottom-md"><strong>Accuracy of Information:</strong> The content on Karbi Lamtasam, including words, pronunciations, meanings, and translations, is based on the book titled <em>Karbi Lamtasam</em>, edited by Khoyasing Hanse. While I strive to present this information accurately, Karbi Lamtasam does not guarantee the completeness or reliability of any information provided.</li>
              <li class="margin-bottom-md"><strong>Modification of Content:</strong> I reserve the right to modify, update, remove content on the website at any time without prior notice.</li>
            </ol>
          </div>
          <div class="padding-y-xl9">
            <h3>Intellectual Property</h3>
            <ol>
              <li class="margin-bottom-md"><strong>Ownership:</strong> The content on Karbi Lamtasam is derived from <em>Karbi Lamtasam</em>, a book edited by Khoyasing Hanse. All rights to the original content belong to the respective owner(s) and editor of the book. The website serves as a digital platform to share and promote the Karbi language, based on this book.</li>
              <li class="margin-bottom-md"><strong>Use of Content</strong> You may view, download, and print content from Karbi Lamtasam for personal, educational, and non-commercial use only. Any other use, including reproduction, distribution, or modification of the content, requires written permission.</li>
            </ol>
          </div>
          <div class="padding-y-xl9">
            <h3>Limitation of Liability</h3>
            <p>Karbi Lamtasam is provided "as is" without any warranties, express or implied. I do not guarantee that the website will be available at all times or free from errors. Under no circumtances shall Karbi Lamtasam or its owners be liable for any damages arising from your use of the website.</p>
          </div>
          <div class="padding-y-xl9">
            <h3>External Links</h3>
            <p>My website may contain links to external websites. Karbi Lamtasam is not responsible for the content or practices of these third-party sites and encourages you to review their terms and policies before engaging with them.</p>
          </div>
          <div class="padding-y-xl9">
            <h3>Changes to the Terms of Service</h3>
            <p>I may update these Terms of Service from time to time. Any changes will be posted on this page, and your continued use of the website after such changes constitutes your acceptance of the new terms.</p>
          </div>
        </div>
      </div>
    </section>
    <div id="contact-info" class="padding-y-xl9">
      <div class="container">
        <h3>Contact Information</h3>
        <p>If you have any questions about these Terms of Service, please contact me at [ currently no contact available ].</p>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'TermsOfService',
  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>

<style></style>