<template>
	<header id="top-header" class="position-sticky-top padding-y-xs">
    <div class="container">
      <div class="nav-container size-md2 tablet:size-md">
        <div class="menu tablet:display-none">
          <a class="menu-button no-underline display-flex flex-items-center flex-content-center padding-x-md padding-y-xs" id="menu-open">
            <img src="/images/icon/menu_icon.svg" alt="menu">
          </a>
        </div>
                        
        <div class="home display-flex flex-items-center flex-content-center tablet:flex-content-start tablet:padding-left-md padding-y-xs">
          <a href="/" class="opacity-90" title="Home">
            <img src="/images/site_logo/kl_logo1.svg" alt="home">
          </a>
        </div>
                        
        <div class="header-links display-none tablet:display-block">
          <div class="display-flex flex-items-center flex-content-end padding-y-xs">
            <a href="#" class="strong no-underline margin-right-md">Karbi Grammar</a>
            <a href="#" class="strong no-underline margin-right-md">Word of the Day</a>
          </div>
        </div>
                        
        <div class="search display-flex flex-items-center flex-content-end tablet:padding-y-xs4">
          <a class="magnifier no-underline display-flex flex-content-center padding-x-md padding-y-xs laptop:padding-xs" title="Search">
            <span class="fa-solid fa-magnifying-glass"></span>
          </a>
        </div>
      </div>
    </div>
                
                
    <div class="side-menu" aria-label="Side menu">
      <div class="menu-header padding-y-xs">
        <div class="display-flex flex-items-center size-md2">
          <div class="display-flex flex-items-center">
            <a id="menu-close" class="no-underline display-flex flex-items-center flex-content-center margin-left-xs2 padding-x-md padding-y-xs">
              <span class="fa-solid fa-xmark"></span>
            </a>
          </div>
          <div class="home display-flex flex-items-center flex-content-center margin-x-md4 padding-y-xs">
            <a href="/" title="Home">
              <img src="/images/site_logo/kl_logo1.svg" alt="home">
            </a>
          </div>
        </div>
      </div>
      <div class="size-md2">
        <a href="#" class="side-menu-link strong no-underline display-block padding-lg">Karbi Grammar</a>
        <a href="#" class="side-menu-link strong no-underline display-block padding-lg">Word of the Day</a>
      </div>       
    </div>    

    <!-- semi-transparent body overlay for side menu -->
    <div id="sitemask"></div>

  </header>
</template>

<script>
import sideMenu from '@/static/js/js-modules/sideMenu';
export default {
  name: 'HeaderComponent',
  mounted() {
    sideMenu();
    console.log('side_menu script has been executed!');
  }
}
</script>
