// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomepageView from '../static/js/views/homepage/HomepageView';
import PrivacyPolicy from '../static/js/views/privacy/PrivacyPolicy';
import TermsOfService from '../static/js/views/terms_of_service/TermsOfService';
import IndexOfWordView from '@/static/js/views/index/IndexOfWordView';
import	AIndex from '../static/js/views/index/AIndex'
import	BIndex from '../static/js/views/index/BIndex'

const routes = [
	{ path: '/', name: 'Home', component: HomepageView },
	{ path: '/privacy', name: 'PrivacyPolicy', component: PrivacyPolicy },
	{ path: '/terms_of_service', name: 'TermsOfService', component: TermsOfService },
	{ 
		path: '/index_of_words',
		name: 'IndexOfWordView',
		component: IndexOfWordView,
		children: [
			{ path: 'a', component: AIndex },
			{ path: 'b', component: BIndex }
		]
	}
];

const router = createRouter({
	history: createWebHistory(), routes
});

export default router;