<template>
	<section id="features" class="padding-y-xl7">
		<div class="container">
			<div class="row">
				<div class="text-center tablet:line-length-lg tablet:text-left laptop:col-6">
					<h1>Everything You Need to Know About Karbi - Free and Accessible!</h1>
					<p>Looking for a free online Karbi dictionary? Karbi Lamtasam is your perfect solution. It's completely free and offers detailed definitions and pronunciation guides for all Karbi words. Find any Karbi word's meaning easily without needing an expensive dictionary.</p>
					<div class="display-flex flex-content-center padding-y-xl">
						<a href="#" class="btn padding-x-xl9 tablet:padding-x-xl12 no-underline" style="background-color: green;">Browse the Dictionary</a>
					</div>
				</div>
			</div>
			<div class="row align-stretch">
				<div class="col-12 tablet:col-6 margin-top-xl">
					<div class="padding-x-sm border-left" style="height: 100%;">
						<h4>Extensive Word List</h4>
						<p>Karbi Lamtasam includes a wide range of Karbi words, from common to specialized terms, covering various language needs.</p>
					</div>
				</div>
				<div class="col-12 tablet:col-6 margin-top-xl">
					<div class="padding-x-sm border-left" style="height: 100%;">
						<h4>Free Content</h4>
						<p>Karbi Lamtasam provides full set of definitions and reference information at no cost. It's available online without payment or registration.</p>
					</div>
				</div>
			</div>
			<div class="row align-stretch">
				<div class="col-12 tablet:col-6 margin-top-xl">
					<div class="padding-x-sm border-left" style="height: 100%;">
						<h4>Translation of Definitions</h4>
						<p>Accurate translation makes definitions clear for everyone. Karbi words are translated into English, preserving their meaning.</p>
					</div>
				</div>
				<div class="col-12 tablet:col-6 margin-top-xl">
					<div class="padding-x-sm border-left" style="height: 100%;">
						<h4>User-Friendly Interface</h4>
						<p>The dictionary has a simple and easy-to-use interface. Find and understand information quickly and effortlessly.</p>
					</div>
				</div>
				<!--<div>
					<h4>Community Contributions</h4>
					<p>Users can contribute to the dictionary by suggesting new words or corrections, helping to enrich the resource and keep it accurate. This collaborative approach ensures that Karbi Lamtasam reflects the language's dynamic nature and serves the needs of its community.</p>
				</div>-->
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'FeatureS'
}
</script>

<style></style>