<template>
  <div class="main-container">
    <HeaderComponent />
    <router-view />
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from '@/static/js/kl-wappcomponents/header/Header.vue';
import FooterComponent from '@/static/js/kl-wappcomponents/footer/Footer.vue';

export default {
  components: {
    HeaderComponent,
    FooterComponent
  }
};
</script>
