<template>
  <main role="main">
    <section id="hero" class="padding-y-xl7">
      <div class="container">
        <h1 class="size-xl2 margin-bottom-xs">Privacy Policy</h1>
        <p style="color: grey;">Updated: 11 Aug, 2024</p>
      </div>
    </section>
    <section id="contents" class="padding-top-xl8 padding-bottom-xl4">
      <div class="container">
        <h1>Contents</h1>
        <div class="size-md2">
          <div class="padding-y-lg">
            <a href="privacy#intro" class="no-underline">Introduction</a>
          </div>
          <div class="padding-y-lg">
            <a href="privacy#collection" class="no-underline">Information Karbi Lamtasam collects</a>
          </div>
          <div class="padding-y-lg">
            <a href="privacy#usage" class="no-underline">Use of non-personal information</a>
          </div>
          <div class="padding-y-lg">
            <a href="privacy#security" class="no-underline">Data security</a>
          </div>
          <div class="padding-y-lg">
            <a href="privacy#third-party" class="no-underline">Third-Party links</a>
          </div>
          <div class="padding-y-lg">
            <a href="privacy#changes" class="no-underline">Changes to this Privacy Policy</a>
          </div>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="privacy-contents">
        <div id="intro" class="padding-y-xl9">
          <p>Welcome to Karbi Lamtasam, a free online dictionary for the Karbi language. I value your privacy and am committed to protecting your personal information. This Privacy Policy outlines how I collect, use, and protect your data when you visit and use my website.</p>
        </div>
        <div id="collection" class="padding-y-xl9">
          <h3>Information Karbi Lamtasam Collect</h3>
          <ul>
            <li class="margin-bottom-md"><strong>Personal Information:</strong> I do not collect any personal information, such as names, email addresses, or phone numbers, from visitors to my website.</li>
            <li class="margin-bottom-md"><strong>Non-Personal Information:</strong> I may collect non-personal information such as IP addresses, browser types, and usage data to help improve the functionality and performance of my website.</li>
          </ul>
        </div>
        <div id="usage" class="padding-y-xl8">
          <h3>Use of Non-Personal Information</h3>
          <ul>
            <li class="margin-bottom-md"><strong>Website Analytics:</strong> The non-personal information I collect is used solely for analyzing website traffic, monitoring usage patterns, and enhancing user experience.</li>
            <li class="margin-bottom-md"><strong>Cookies:</strong> I may use cookies to improve your browsing experience. Cookies are small data files placed on your device that help me remember your preferences and enhance website functionality.</li>
          </ul>
        </div>
        <div id="security" class="padding-y-xl9">
          <h3>Data Security</h3>
          <p>I employ reasonable measures to secure your non-personal information against unauthorized access, alteration, or disclosure. However, please note that no method of data transmission over the internet or elctronic storage is completely secure.</p>
        </div>
        <div id="third-party" class="padding-y-xl9">
          <h3>Third-Party Links</h3>
          <p>My website may contain links to external websites. I am not responsible for the privacy practices or content of these third-party sites. I recommend reviewing the privacy policies of any external sites you visit.</p>
        </div>
        <div id="changes" class="padding-y-xl9">
          <h3>Changes to This Privacy Policy</h3>
          <p>I may update this Privacy Policy periodically. Any changes will be posted on this page with an updated effective date. I encourage you to review this Privacy Policy regularly.</p>
        </div>
      </div>
    </div>
  </main>
	<div id="contact-info" class="padding-y-xl8">
    <div class="container">
      <h3>Contact Information</h3>
      <p>If you have any questions or concerns regarding this Privacy Policy, please contact me at [ currently no contact available ].</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
