<template>
	<section id="showcase" class="padding-y-xl9">
		<div class="container">
			<div class="text-center line-length-md margin-auto">
				<h1 class="showcase-h1 size-xl3">Discover the Rich Vocabulary of the Karbi Language</h1>
				<p class="size-md3 margin-y-lg3">Karbi Lamtasam is an online dictionary aimed at preserving and promoting the Karbi language and culture.</p>
				<div class="search-bar display-flex flex-items-center flex-content-start padding-y-xs padding-x-md tablet:margin-x-xl4">
					<span class="margin-right-xs3" style="cursor: default !important;">
						<span class="fa-solid fa-magnifying-glass"></span>
					</span>
					<form id="search-word" method="post">
						<input type="search" placeholder="Search" id="my-input" name="word" required>
					</form>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'ShowCase'
}
</script>

<style></style>