<template>
  <div id="b">
    <h3>Words starting with B</h3>
    <a class="Aa">b</a>
    <a class="Aa">ba</a>
    <a class="Aa">baha</a>
    <a class="Aa">bara</a>
  </div>
</template>
<script>
export default {
  name: 'BIndex'
}
</script>

<style scoped>
.Aa {
  display: block;
  padding: 0.5em 0;
}
</style>