export default function sideMenu() {
  const menuToggle = document.querySelector('.side-menu');
  const menuOpen = document.getElementById('menu-open');
  const menuClose = document.getElementById('menu-close');
  const sitemask = document.getElementById('sitemask');
  function click() {
    menuToggle.classList.toggle('show');
    document.body.removeAttribute('data-body-overlay--active');
    sitemask.removeAttribute('visible');
  }
  if (sideMenu) {
    menuOpen.addEventListener('click', () => {
      menuToggle.classList.toggle('show');
      document.body.setAttribute('data-body-overlay--active', '');
      sitemask.setAttribute('visible','');
    });
    menuClose.addEventListener('click', click);sitemask.addEventListener('click', click);
  } else {
    console.log('Error: Element not found');
  }
}